import {
  Editable,
  EditableInput,
  EditablePreview,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

export default function LeaderBoardRow({
  item,
  index,
  handleChangePoint,
}) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index + 1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.mode_type}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.cases}
        </Text>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.first_factor}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'first_factor', parseFloat(e))}
        >
          <EditablePreview />
          <EditableInput width="100px"/>
        </Editable>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.second_factor_base}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'second_factor_base', parseFloat(e))}
        >
          <EditablePreview />
          <EditableInput width="100px"/>
        </Editable>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.second_factor_multiplier || '0'}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'second_factor_multiplier', parseFloat(e))}
        >
          <EditablePreview />
          <EditableInput width="100px"/>
        </Editable>
      </Td>
    </Tr>
  );
}
