import {
  Center,
  Flex,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { getNewEventSkillRank } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useEffect, useState } from "react";
import ConditionEventManagement from "./condition";
import DetailEvent from "./detail";
import ButtonType from "components/Button";
import CreateAndUpdateSkillRank from "./CreateAndUpdateSkillRank";
import ModalLeaderBoard from "./ModalLeaderBoard";
import ModalRankDistribution from "./ModalRankDistribution";
import ModalSkillPoint from "./ModalSkillPoint";
import ModalSendItems from "./ModalSendItems";
import ModalRegister from "./ModalRegister";
import { NoDataTableIcon } from "components/Icons/NoDataTableIcon";
// import CreateAndUpdateStandard from "./CreateAndUpdateStandard";
// import ModalLeaderBoard from "./ModalLeaderBoard";

export default function SkillRankEvent() {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const toast = useToast();

  const [modal, setModal] = useState({
    addNew: false,
    delete: false,
    rankDist: false,
    skillPts: false,
    leaderBoard: false,
    itemSend: false,
    registered: false,
  })
  
  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setDataDetail(data)
  }

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getNewEventSkillRank({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setEvents(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  return (
    <Flex flexDirection="column">
      <Card p="0px">
        <CardHeader mb="20px">
          <Flex alignItems="center" w="100%" justifyContent="flex-end" gap={6} mb="10px">
            <ButtonType
              text="Rank Dist."
              w="140px"
              fontSize="16px"
              onClick={() => toggle("rankDist", null)}
            />
            <ButtonType
              text="Skill Pts Config."
              btnType="primary-new-outline"
              fontSize="16px"
              w="140px"
              onClick={() => toggle("skillPts", null)}
            />
          </Flex>
        </CardHeader>
        <CardHeader mb="32px">
          <ConditionEventManagement
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
              <Flex alignItems="flex-end" gap={6} mb="10px">
                <ButtonType
                  text="NEW"
                  w="100px"
                  fontSize="12px"
                  onClick={() => toggle("addNew", null)}
                />
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Flex flexWrap="wrap" gap="6px" w="100%">
            {events?.length > 0 ? (
              events?.map((event, index) => (
                <DetailEvent 
                  item={event}
                  key={index}
                  toggle={toggle}
                />
              ))
            ) : (
              <Flex w="100%" justifyContent="center">
                <Center w="100%" color="#000000">
                  <Icon as={NoDataTableIcon} w="250px" h="200px" mt="20px" />
                </Center>
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
      {modal.addNew && (
        <CreateAndUpdateSkillRank
          isOpen={modal.addNew}
          onClose={() => toggle("addNew", null)}
          detailEvent={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {modal.leaderBoard && (
        <ModalLeaderBoard
          isOpen={modal.leaderBoard}
          onClose={() => toggle("leaderBoard", null)}
          dataDetail={dataDetail}
        />
      )}
      {modal.rankDist && (
        <ModalRankDistribution
          isOpen={modal.rankDist}
          onClose={() => toggle("rankDist", null)}
        />
      )}
      {modal.skillPts && (
        <ModalSkillPoint
          isOpen={modal.skillPts}
          onClose={() => toggle("skillPts", null)}
        />
      )}
      {modal.itemSend && (
        <ModalSendItems 
          isOpen={modal.itemSend}
          onClose={() => toggle("itemSend", null)}
        />
      )}
      {modal.registered && (
        <ModalRegister
          isOpen={modal.registered}
          onClose={() => toggle("registered", null)}
          dataDetail={dataDetail}
          setDataDetail={setDataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
