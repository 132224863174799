import { DocumentIcon, HomeIcon, PersonIcon } from "components/Icons/Icons";
import { FaUsers } from "react-icons/fa";
import { GiPresent, GiVendingMachine } from "react-icons/gi";
import { MdEventNote } from "react-icons/md";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import Default from "views/Dashboard/Default";
import EventManagement from "views/Event/EventManager";
import EventManagementNew from "views/Event/EventManagement";
import RulesManagement from "views/Event/EventRule";
import EventTicket from "views/Event/EventTicket";
import GameModePage from "views/GameMode";
import GameExperiencePoint from "views/GameExperiencePoint";
import MachineGamePlay from "views/Machine/MachineGamePlay";
import MachineMap from "views/Machine/MachineMap";
import MachineSettings from "views/Machine/MachineSettings";
import MachineState from "views/Machine/MachineState";
import Maintenance from "views/Maintenance";
import PlayFee from "views/PlayFee";
import PrizeSettings from "views/PrizeSettings";
import ProjectCategory from "views/ProjectCategory";
import RevenueSharing from "views/RevenueSharing";
import RoleSystemPage from "views/RoleSystem";
import UserManagement from "views/Users/UserManagement";
import ImportNftHunterPage from "views/Hunter/import";
import ListNftHunterPage from "views/Hunter/list";
import NftHunterManagementPage from "views/NFTsManagement/HunterNFTs";
import NftGauntletManagementPage from "views/NFTsManagement/GauntletNFTs";
import NftBountyBallManagementPage from "views/NFTsManagement/BountyBallNFTs";
import NftGenesisManagementPage from "views/NFTsManagement/GenesisNFTs";
import NftImportManagementPage from "views/NFTsManagement/NFTsImport";
import DetailNFTsHunter from "views/Hunter/list/detail";
import DetailNFTsGenesis from "views/Genesis/list/detail";
import { RoleIcon } from "components/Icons/RoleIcon";
import { ManagementHunterIcon } from "components/Icons/ManagementHunterIcon";
import { ManagementGenesis } from "components/Icons/ManagementGenesis";
import ImportNftGenesisPage from "views/Genesis/import";
import ListNftGenesisPage from "views/Genesis/list";
import AdminManagement from "views/AdminManagement";
import PlayerManagement from "views/Users/PlayerManagement";
import { GameControlIcon } from "components/Icons/GameControlIcon";
import ImportNftGauntletPage from "views/Gauntlet/import";
import ListNftGauntletPage from "views/Gauntlet/list";
import { GauntletIcon } from "components/Icons/GauntletIcon";
import DetailNFTsGauntlet from "views/Gauntlet/list/detail";
import { BountyBallManagementIcon } from "components/Icons/BountyBallManagementIcon";
import ImportNftBountyBallPage from "views/BountyBall/import";
import ListNftBountyBallPage from "views/BountyBall/list";
import DetailNFTsBountyBall from "views/BountyBall/list/detail";
import { WalletManagementIcon } from "components/Icons/WalletManagementIcon";
import WalletManagement from "views/WalletManagement";
import { ExchangeRateIcon } from "components/Icons/ExchangeRateIcon";
import ExchangeRateSetting from "views/ExchangeRateSetting";
import Contact from "views/Contact";
import DigitalManagement from "views/DigitalFigure";
// import ContactTemplate from "views/ContactTemplate";
import Charts from "views/Pages/Charts";
import StatisticsSetting from "views/Dashboard/Statistics";
import { StatisticsIcon } from "components/Icons/StatisticsIcon";
import { DigitalFigureIcon } from "components/Icons/DigitalFigure";
import SkillRankPage from "views/SkillRank";
// import SkillPointSettings from "views/SkillPointSettings"
import HunterOffChainPage from "views/HunterOffChain";
import LevelRequiredPage from "views/LevelRequired";
import { MaintenanceIcon } from "components/Icons/MaintenanceIcon";

const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: false,
    component: Default,
    layout: "/admin",
    
  },
  {
    name: "PAGES",
    category: "pages",
    isHide: true,
    items: [
      {
        name: "Authentication",
        path: "/authentication",
        icon: <PersonIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Sign In",
            path: "/authentication/sign-in",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignInBasic,
                path: "/authentication/sign-in/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignInCover,
                path: "/authentication/sign-in/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignInIllustration,
                path: "/authentication/sign-in/illustration",
                layout: "/auth",
              },
            ],
          },
          {
            name: "Sign Up",
            path: "/authentication/sign-up",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignUpBasic,
                path: "/authentication/sign-up/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignUpCover,
                path: "/authentication/sign-up/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignUpIllustration,
                path: "/authentication/sign-up/illustration",
                layout: "/auth",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Crane Machines",
    path: "/machine",
    icon: <GiVendingMachine color="inherit" />,
    authIcon: <GiVendingMachine color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Machines Map",
        path: "/machine/machine-map",
        component: MachineMap,
        layout: "/admin",
      },
      // {
      //   name: "Real Machines State",
      //   path: "/machine/real-machines-state",
      //   component: MachineState,
      //   layout: "/admin",
      // },
      {
        name: "Machine Settings",
        path: "/machine/machine-settings",
        component: MachineSettings,
        layout: "/admin",
      },
      {
        name: "Gameplay Type",
        path: "/machine/gameplay",
        component: MachineGamePlay,
        layout: "/admin",
      },
    ],
  },
  // {
  //   name: "Prizes",
  //   path: "/prize",
  //   icon: <GiPresent color="inherit" width="16px" heigth="16px" />,
  //   authIcon: <GiPresent color="inherit" width="16px" heigth="16px" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Prize Settings",
  //       path: "/prize/prize-settings",
  //       component: PrizeSettings,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Project Category",
  //       path: "/prize/project-category",
  //       component: ProjectCategory,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Play Fee",
  //       path: "/prize/play-fee",
  //       component: PlayFee,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Revenue Sharing",
  //       path: "/prize/revenue-sharing",
  //       component: RevenueSharing,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    name: "User Management",
    path: "/user",
    icon: <FaUsers color="inherit" />,
    authIcon: <FaUsers color="inherit" />,
    component: UserManagement,
    collapse: true,
    items: [
      {
        name: "User Management",
        path: "/user/user-management",
        component: UserManagement,
        layout: "/admin",
      },
      {
        name: "Player Management",
        path: "/user/player-management",
        component: PlayerManagement,
        layout: "/admin",
      },
      // {
      //   name: "Player Level Settings",
      //   path: "/user/player-level-settings",
      //   component: UserManagement,
      //   layout: "/admin",
      // },
    ],
  },
  // {
  //   name: "Events",
  //   path: "/event",
  //   icon: <MdEventNote color="inherit" />,
  //   authIcon: <MdEventNote color="inherit" />,
  //   component: EventManagement,
  //   collapse: true,
  //   items: [
  //     // {
  //     //   name: "Events Management",
  //     //   path: "/event/event-management",
  //     //   component: EventManagement,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       name: "Events Management",
  //       path: "/event/new-event-management",
  //       component: EventManagementNew,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Event Rule",
  //       path: "/event/event-rule",
  //       component: RulesManagement,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Event Ticket",
  //       path: "/event/event-ticket",
  //       component: EventTicket,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    name: "Game Control Manager",
    path: "/game-control",
    icon: <GameControlIcon color="inherit" />,
    authIcon: <GameControlIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Prize Settings",
        path: "/game-control/prize-settings",
        component: PrizeSettings,
        layout: "/admin",
      },
      {
        name: "Events Management",
        path: "/game-control/new-event-management",
        component: EventManagementNew,
        layout: "/admin",
      },
      // {
      //   name: "Skill Rank",
      //   path: "/game-control/skill-rank",
      //   component: SkillRankPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Skill Point Settings",
      //   path: "/game-control/skill-point-settings",
      //   component: SkillPointSettings,
      //   layout: "/admin",
      // },
      // {
      //   name: "Hunter Off-chain",
      //   path: "/game-control/off-chain",
      //   component: HunterOffChainPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Level Required",
      //   path: "/game-control/level-required",
      //   component: LevelRequiredPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Experience Point Reward",
      //   path: "/game-control/experience-point",
      //   component: GameExperiencePoint,
      //   layout: "/admin",
      // },
      {
        name: "Game Mode",
        path: "/game-control/game-mode",
        component: GameModePage,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Admin Account",
    path: "/system",
    icon: <RoleIcon color="inherit" />,
    authIcon: <RoleIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Admin Management",
        path: "/system/admin-management",
        layout: "/admin",
        rule: "MANAGEMENT_ADMIN",
        component: AdminManagement,
      },
      {
        name: "Role System",
        path: "/system/role-system",
        layout: "/admin",
        rule: "MANAGEMENT_PERMISSIONS",
        component: RoleSystemPage,
      },
    ],
  },
  {
    name: "NFTs Management",
    path: "/nfts",
    icon: <ManagementGenesis color="inherit" />,
    authIcon: <ManagementGenesis color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Hunter NFTs",
        path: "/nfts/hunter-nfts-management",
        component: NftHunterManagementPage,
        layout: "/admin",
      },
      {
        name: "Gauntlet NFTs",
        path: "/nfts/gauntlet-nfts-management",
        component: NftGauntletManagementPage,
        layout: "/admin",
      },
      {
        name: "Bounty Ball NFTs",
        path: "/nfts/bounty-ball-nfts-management",
        component: NftBountyBallManagementPage,
        layout: "/admin",
      },
      {
        name: "Genesis NFTs",
        path: "/nfts/genesis-nfts-management",
        component: NftGenesisManagementPage,
        layout: "/admin",
      },
      {
        name: "Import",
        path: "/nfts/import",
        component: NftImportManagementPage,
        layout: "/admin",
      },
    ],
  },
  // TODO: Comment old feature
  // {
  //   name: "Manage NFT Genesis",
  //   path: "/genesis",
  //   icon: <ManagementGenesis color="inherit" />,
  //   authIcon: <ManagementGenesis color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Import NFTs",
  //       path: "/genesis/import-nft",
  //       component: ImportNftGenesisPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "List NFTs",
  //       path: "/genesis/list-nft",
  //       component: ListNftGenesisPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Detail NFTs",
  //       nameParent: "List NFTs",
  //       pathNotSlug: "/genesis/nft-detail",
  //       path: "/genesis/nft-detail/:id",
  //       component: DetailNFTsGenesis,
  //       layout: "/admin",
  //       isHide: true,
  //     },
  //   ],
  // },
  // {
  //   name: "Manage NFT Hunter",
  //   path: "/hunter",
  //   icon: <ManagementHunterIcon color="inherit" />,
  //   authIcon: <ManagementHunterIcon color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Import NFTs",
  //       path: "/hunter/import-nft",
  //       component: ImportNftHunterPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "List NFTs",
  //       path: "/hunter/list-nft",
  //       component: ListNftHunterPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Detail NFTs",
  //       nameParent: "List NFTs",
  //       pathNotSlug: "/hunter/nft-detail",
  //       path: "/hunter/nft-detail/:id",
  //       component: DetailNFTsHunter,
  //       layout: "/admin",
  //       isHide: true,
  //     },
  //   ],
  // },
  // {
  //   name: "Manage NFT Gauntlet",
  //   path: "/gauntlet",
  //   icon: <GauntletIcon color="inherit" />,
  //   authIcon: <GauntletIcon color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Import NFTs",
  //       path: "/gauntlet/import-nft",
  //       component: ImportNftGauntletPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "List NFTs",
  //       path: "/gauntlet/list-nft",
  //       component: ListNftGauntletPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Detail NFTs",
  //       nameParent: "List NFTs",
  //       pathNotSlug: "/gauntlet/nft-detail",
  //       path: "/gauntlet/nft-detail/:id",
  //       component: DetailNFTsGauntlet,
  //       layout: "/admin",
  //       isHide: true,
  //     },
  //   ],
  // },
  // {
  //   name: "Manage NFT Bounty Ball",
  //   path: "/bounty-ball",
  //   icon: <BountyBallManagementIcon color="inherit" />,
  //   authIcon: <BountyBallManagementIcon color="inherit" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Import NFTs",
  //       path: "/bounty-ball/import-nft",
  //       component: ImportNftBountyBallPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "List NFTs",
  //       path: "/bounty-ball/list-nft",
  //       component: ListNftBountyBallPage,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Detail NFTs",
  //       nameParent: "List NFTs",
  //       pathNotSlug: "/bounty-ball/nft-detail",
  //       path: "/bounty-ball/nft-detail/:id",
  //       component: DetailNFTsBountyBall,
  //       layout: "/admin",
  //       isHide: true,
  //     },
  //   ],
  // },
  {
    name: "Contact",
    path: "/contact",
    icon: <StatisticsIcon color="inherit" />,
    authIcon: <StatisticsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Contact List",
        path: "/contact/list",
        component: Contact,
        layout: "/admin",
      },
      // {
      //   name: "Contact Template",
      //   path: "/contact/template",
      //   component: ContactTemplate,
      //   layout: "/admin",
      // },
    ],
  },
  {
    name: "Settings",
    path: "/setting",
    icon: <WalletManagementIcon color="inherit" />,
    authIcon: <WalletManagementIcon color="inherit" />,
    component: WalletManagement,
    collapse: true,
    items: [
      {
        name: "Manage Wallet",
        path: "/setting/manage-wallet",
        component: WalletManagement,
        layout: "/admin",
      },
      {
        name: "Exchange Rate",
        path: "/setting/exchange-rate-settings",
        component: ExchangeRateSetting,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Digital Figure Management",
    path: "/digital",
    icon: <DigitalFigureIcon color="inherit" />,
    authIcon: <DigitalFigureIcon color="inherit" />,
    component: DigitalManagement,
    layout: "/admin",
    collapse: false,
  },
  // {
  //   name: "Statistics",
  //   path: "/statistics",
  //   icon: <StatisticsIcon color="inherit" />,
  //   authIcon: <StatisticsIcon color="inherit" />,
  //   component: StatisticsSetting,
  //   layout: "/admin",
  //   collapse: false,
  // },
  // {
  //   name: "Maintenance",
  //   path: "/maintenance",
  //   icon: <MaintenanceIcon color="inherit" />,
  //   authIcon: <MaintenanceIcon color="inherit" />,
  //   component: Maintenance,
  //   layout: "/admin",
  //   collapse: false,
  // },
];

export default dashRoutes;
