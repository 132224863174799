import { createSlice } from "@reduxjs/toolkit";
import { USER_NAMESPACE } from "stores/constant";
import { listUsersApi } from "./action";
import { GENESIS_NFT_TABS_VALUES } from "views/NFTsManagement/constants";

const initialState = {
  loading: false,
  indexTabFilter: GENESIS_NFT_TABS_VALUES.POLYGON,
  indexTabImport: 0,
  summaryData: {},
};

const nftsSlice = createSlice({
  name: USER_NAMESPACE,
  initialState,
  reducers: {
    setIndexTabFilter(state, { payload }) {
      state.indexTabFilter = payload;
    },
    setIndexTabImport(state, { payload }) {
      state.indexTabImport = payload;
    },
    setSummaryData(state, { payload }) {
      state.summaryData = payload;
    },
  },
  extraReducers: {
    // list user
    [listUsersApi.pending.type]: (state) => {
      state.loading = true;
    },

    [listUsersApi.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = "";
      if (action.payload) {
        state.listUsers = action.payload;
      }
    },

    [listUsersApi.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setIndexTabFilter, setIndexTabImport, setSummaryData } =
  nftsSlice.actions;
export const nftsSliceReducer = nftsSlice.reducer;
