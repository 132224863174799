import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getEventManagements = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT}?${params}`);
};

export const getDetailEventManagement = (id) => {
  return http.get(`${END_POINT.EVENT}/${id}`);
};

export const createNewEvent = (data, option = {}) => {
  return http.post(END_POINT.EVENT, data, option);
};

export const getNewEventSkillRank = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT_SKILL}?${params}`);
};

export const createNewEventSkillRank = (data, option = {}) => {
  return http.post(END_POINT.EVENT_SKILL, data, option);
};

export const editEventSkillRank = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT_SKILL}/${idEdit}`, data, option);
};

export const editWhiteListEvent = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT}/change-has-whitelist/${idEdit}`, data, option);
};

export const getNewEventStandard = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT_STANDARD}?${params}`);
};

export const createNewEventStandard = (data, option = {}) => {
  return http.post(END_POINT.EVENT_STANDARD, data, option);
};

export const editEventStandard = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT_STANDARD}/${idEdit}`, data, option);
};

export const editEvent = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT}/${idEdit}`, data, option);
};

export const deleteEvent = (id) => {
  return http.delete(`${END_POINT.EVENT}/${id}`);
};

// rule

export const getRulesEvent = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT_RULE}?${params}`);
};

export const deleteRuleEvent = (id) => {
  return http.delete(`${END_POINT.EVENT_RULE}/${id}`);
};

export const createRuleEvent = (data, option = {}) => {
  return http.post(END_POINT.EVENT_RULE, data, option);
};

export const editRuleEvent = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.EVENT_RULE}/${idEdit}`, data, option);
};

//white list
export const getDetailWhiteList = (id, p) => {
  return http.get(`${END_POINT.EVENT_WHITE_LIST}/event/${id}?${p}`);
};

export const addUserIntoWhiteList = (data) => {
  return http.post(`${END_POINT.EVENT_WHITE_LIST}`, data);
};

export const addEmailIntoWhiteList = (id, data) => {
  return http.post(`${END_POINT.EVENT_WHITE_LIST}/add-emails/${id}`, data);
}

export const deleteEmailFromWhiteList = (id, data) => {
  return http.delete(`${END_POINT.EVENT_WHITE_LIST}/remove-users/${id}`, data);
}

export const deleteWhiteListEvent = (id) => {
  return http.delete(`${END_POINT.EVENT_WHITE_LIST}/${id}`);
};

export const getUserWhiteListEvent = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_EVENT}/${id}?${params}`);
};

export const importWhiteListCSV = (id, data) => {
  return http.post(`${END_POINT.EVENT_WHITE_LIST}/import-csv/${id}`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

//ticket
export const provideTicket = (data) => {
  return http.post(`${END_POINT.EVENT_TICKET}`, data);
};

// leader-board-standard
export const getLeaderboardStandard = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.STANDARD_LEADER_BOARD}/${id}?${params}`);
};

export const getLeaderboardStandardExcluded = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.STANDARD_LEADER_BOARD}/excluded-user/${id}?${params}`);
};

export const leaderboardExcludeUsers = (id, data) => {
  return http.post(`${END_POINT.STANDARD_LEADER_BOARD}/exclude-users/${id}`, data);
};

export const leaderboardIncludeUsers = (id, data) => {
  return http.post(`${END_POINT.STANDARD_LEADER_BOARD}/include-users/${id}`, data);
};

export const getRegisterLeaderboard = (id, p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.STANDARD_LEADER_BOARD}/register-list/${id}?${params}`);
};

// leader-board-skill-rank
export const getLeaderboardSkillRank = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SKILL_RANK_LEADER_BOARD}?${params}`);
};

export const getSkillRankExcluded = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SKILL_RANK_LEADER_BOARD}/excluded-user?${params}`);
};

export const leaderboardSkillRankExcludeUsers = (data) => {
  return http.post(`${END_POINT.SKILL_RANK_LEADER_BOARD}/exclude-users`, data);
};

export const leaderboardSkillRankIncludeUsers = (data) => {
  return http.post(`${END_POINT.SKILL_RANK_LEADER_BOARD}/include-users`, data);
};

export const getRegisterSkillRank = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SKILL_RANK_LEADER_BOARD}/register-list?${params}`);
};

// user-simple-resource
export const getUserSimpleResource = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EVENT_SIMPLE_RESOURCE}?${params}`);
};

export const sendResourceUser = (data) => {
  return http.put(`${END_POINT.EVENT_SEND_RESOURCE}`, data);
};
