import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getGamePlayType } from "api/gameplayType.api";
import {
  getContacts,
} from "api/contact.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ContactRow from "components/Tables/ContactRow";
import { useCallback, useEffect, useState } from "react";
import ContactUpdate from "./components/updateContact";
import ConditionMachine from "./condition";
import DetailContact from "./components/detailContact";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    sortColumn: 'create_time-desc',
  });
  const toast = useToast();
  const [contacts, setContacts] = useState([]);

  const labels = [
    "ID",
    "MachineID",
    "Contact Category",
    "Message",
    "Note",
    "Priority",
    "Create At",
    "Status",
    "",
  ];
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSetDataDetail = (data) => {
    onOpenDetail();
    setDataDetail(data);
  };

  const handleClose = () => {
    onCloseDetail();
    onClose();
    setDataDetail(null);
  };

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getContacts({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setContacts(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListMachine();
  }, [getListMachine]);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Contact 
            </Text>
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionMachine
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={contacts}
          >
            <>
              {contacts?.map((item) => {
                return (
                  <ContactRow
                    key={item?.id}
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item);
                    }}
                    handleOpenCreateOrUpdate={() => {
                      handleOpenCreateOrUpdate(item);
                    }}
                    contact={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpen && (
        <ContactUpdate
          isOpen={isOpen}
          onClose={handleClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {isOpenDetail && (
        <DetailContact
          isOpen={isOpenDetail}
          onClose={handleClose}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
