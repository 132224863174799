export const SORT = [
  { label: "Desc", value: "create_time-desc" },
  { label: "Asc", value: "create_time-asc" },
];

export const STATUS_CONTACT = [
  { label: "Open", value: "OPENED" },
  { label: "Pending", value: "PENDING" },
  { label: "Closed", value: "CLOSED" },
  { label: "Cancel", value: "CANCEL" },
];

export const MACHINE_CONTACT_CATEGORIES  = {
  CRANE_CONTROL_BUTTON_NOT_WORKING: "Crane control button not working",
  CAMERA_NOT_WORKING: "Camera not working",
  CAMERA_IN_WRONG_DIRECTION: "Camera in wrong direction",
  PRIZE_GRABBED_BUT_NOT_REGISTERED: "Prize Grabbed but not registered",
  RESET_CRANE_TO_HOME_POSITION: "Reset crane to home position"
}

export const PRIORITY  = {
  IMMEDIATE: "Immediate",
  HIGH: "High",
  NORMAL: "Normal",
  LOW: "Low"
}

export const STATUS = {
  OPENED: "Opened",
  PENDING: "Pending",
  CLOSED: "Closed",
  CANCEL: "Cancel"
}
