import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { addEmailIntoWhiteList, editWhiteListEvent, getRegisterLeaderboard } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import SwitchType from "components/Switch";
import ModalWhiteList from "./WhiteList";
import { ERROR_CREATE_EVENT, ERROR_WHITELIST } from "../../constant";
import ImportWhiteList from "./ImportWhiteList";
// import ConfirmSend from "./ConfirmSend";

export default function ModalRegister({
  isOpen,
  onClose,
  dataDetail,
  setDataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const labels = [
    "User",
    "Register At",
  ];
  const [checkedIds, setCheckedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenWhiteList,
    onOpen: onOpenWhiteList,
    onClose: onCloseWhiteList,
  } = useDisclosure();

  const {
    isOpen: isOpenImportWhiteList,
    onOpen: onOpenImportWhiteList,
    onClose: onCloseImportWhiteList,
  } = useDisclosure();

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getRegisterLeaderboard(dataDetail?.id, {
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(items);
    } else {
      setCheckedIds([]);
    }
  };

  
  const handleUpdateWhitelist = async (isWhitelist) => {
    const dataSubmit = {
      has_whitelist: isWhitelist
    }
    try {
      const res = await editWhiteListEvent(dataDetail?.id, dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Whitelist Updated Successfully.",
          description: "Whitelist Updated Successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setDataDetail(prev => ({
          ...prev,
          has_whitelist: isWhitelist
        }))
        handleCreateOrUpdateSuccess()
      } else {
        toast({
          description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const handleAddWhitelist = async () => {
    const listUser = checkedIds?.map((check) => check?.user?.email)
    try {
      const res = await addEmailIntoWhiteList(dataDetail?.id, listUser);
      if (res?.data?.success) {
        toast({
          title: "Add User To Whitelist Successfully.",
          description: "Add User To Whitelist Successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setCheckedIds([])
      } else {
        toast({
          description: ERROR_WHITELIST[res?.data?.message] || ERROR_WHITELIST[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Registered Users
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                <Flex justifyContent="space-between" gap="8px">
                  <ButtonType
                    text="White List"
                    fontSize="16px"
                    sizeIcon="16px"
                    onClick={onOpenWhiteList}
                    // disabled={!dataDetail?.has_whitelist}
                  />
                  <Flex alignItems="center" gap="14px">
                    <Text fontSize="20px" fontWeight="600">Whitelist</Text>
                    <SwitchType
                      color="#ff6db6"
                      colorThumb="#fff"
                      size="lg"
                      colorScheme="teal"
                      position="relative"
                      isChecked={dataDetail?.has_whitelist}
                      onChange={(e) => handleUpdateWhitelist(e?.target?.checked)}
                    >
                      <Box
                        as="span"
                        position="absolute"
                        left="25%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        color="white"
                        fontWeight="bold"
                        fontSize="12px"
                        pointerEvents="none" // Prevents pointer events from interfering with the switch
                      >
                        On
                      </Box>
                      {!dataDetail?.has_whitelist && (
                        <Box
                          as="span"
                          position="absolute"
                          left="64%"
                          top="50%"
                          transform="translate(-50%, -50%)"
                          fontWeight="bold"
                          color="#4a4a4a"
                          fontSize="12px"
                          pointerEvents="none" // Prevents pointer events from interfering with the switch
                        >
                          Off
                        </Box>
                      )}
                    </SwitchType>
                  </Flex>
                </Flex>
                <ConditionLeaderBoard
                  isLoading={isLoading}
                  setParams={setParams}
                  params={params}
                />
                {/* <Flex justifyContent="flex-end" gap="8px">
                  <ButtonType
                    text="CSV"
                    fontSize="14px"
                    sizeIcon="16px"
                    iconType="download"
                    disabled={!items?.length}
                    // onClick={onDownload}
                  />
                  <ButtonType
                    text="Import CSV"
                    fontSize="14px"
                    sizeIcon="16px"
                    btnType="primary-new-outline"
                    // onClick={onDownload}
                  />
                </Flex> */}
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isCheck
                  isChecked={
                    items?.length > 0 && checkedIds.length === items?.length
                  }
                  onChangeChecked={onChangeChecked}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!checkedIds?.length}
            onClick={handleAddWhitelist}
          >
            ADD TO WHITELIST
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenWhiteList && (
        <ModalWhiteList 
          isOpen={isOpenWhiteList}
          onClose={onCloseWhiteList}
          dataDetail={dataDetail}
          onOpenImportWhiteList={onOpenImportWhiteList}
        />
      )}
      {isOpenImportWhiteList && (
        <ImportWhiteList
          isOpen={isOpenImportWhiteList}
          onClose={onCloseImportWhiteList}
          idDetail={dataDetail?.id}
        />
      )}
    </Modal>
  );
}
  