export const ENTRY_REQUIREMENT = [
    { label: "None", value: 'NONE' },
    { label: "NFT Certificate", value: "NFT_CERTIFICATE" },
];

export const ERROR_CREATE_EVENT = {
    "INVALID_MODE_TYPE": "Invalid mode type",
    "WHITELIST_MUST_NOT_EMPTY": "Whitelist must not empty",
    "WIN_POINT_MUST_NOT_EMPTY": "Win point must not empty",
    "INVALID_WIN_POINT": "Invalid win point",
    "ENTRY_REQUIREMENT_MUST_NOT_EMPTY": "Entry requirement must not empty",
    "MIN_MATCHES_REQUIREMENT_MUST_NOT_EMPTY": "Min matches requirement must not empty",
    "INVALID_MIN_MATCHES_REQUIREMENT": "Invalid min matches requirement",
    "EVENT_IS_NOT_EXIST": "Event is not exist",
}

export const ERROR_WHITELIST = {
    "EVENT_DOES_NOT_SUPPORT_A_WHITELIST": "Event does not support a whitelist",
    "FAILED_TO_UPLOAD_FILE": "Failed to upload file",
    "EMAIL_NOT_FOUND": "Email not found",
    "EVENT_NOT_FOUND": "Event not fount",
}
