import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = () =>
  yup.object().shape({
    event_name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    event_name_jp: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    // description: yup
    //   .string()
    //   .trim()
    //   .required("Required"),
    // description_jp: yup
    //   .string()
    //   .trim()
    //   .required("Required"),
    icon_url: yup
      .string()
      .trim()
      .required("Required"),
    pre_register_start_time: yup
      .date()
      .required(),
      event_start_time: yup
      .date()
      .test(
        "is-greater-pre_register_start_time",
        "Event start time must be after pre register start time",
        function (value) {
          const { pre_register_start_time } = this.parent;
          return (
            pre_register_start_time &&
            value &&
            new Date(value) > new Date(pre_register_start_time)
          );
        }
      )
      .required(),
    event_end_time: yup
      .date()
      .test(
        "is-greater-event_start_time",
        "Event end time must be after event start time",
        function (value) {
          const { event_start_time } = this.parent;
          return (
            event_start_time &&
            value &&
            new Date(value) > new Date(event_start_time)
          );
        }
      )
      .required(),
    result_end_time: yup
      .date()
      .test(
        "is-greater-event_end_time",
        "Result end time must be after event end time",
        function (value) {
          const { event_end_time } = this.parent;
          return (
            event_end_time &&
            value &&
            new Date(value) > new Date(event_end_time)
          );
        }
      )
      .required(),
  });

export default schemaStandardEvent;
