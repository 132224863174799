import React from "react";
import { Flex, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";
import ButtonType from "components/Button";
import ManagePackageGenesisModal from "../modal/ManagePackageGenesisModal";
import PackageOrderHistoryModal from "../modal/PackageOrderHistoryModal";

const NFTsCardHeader = ({ title, nftMngType }) => {
  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenPackageOrderHistory,
    onOpen: onOpenPackageOrderHistory,
    onClose: onClosePackageOrderHistory,
  } = useDisclosure();

  const {
    isOpen: isOpenManagePackage,
    onOpen: onOpenManagePackage,
    onClose: onCloseManagePackage,
  } = useDisclosure();

  const onManagePackage = () => {
    onOpenManagePackage(true);
  };

  const handleManageSuccess = () => {
    console.log("Manage success");
  };

  return (
    <>
      <CardHeader>
        <Flex
          w="100%"
          gap={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb="20px"
        >
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          {nftMngType === "GENESIS" && (
            <Flex gap={4}>
              <ButtonType
                text="Package Order History"
                fontSize="12px"
                sizeIcon="16px"
                onClick={onOpenPackageOrderHistory}
              />
              <ButtonType
                text="Manage Package"
                fontSize="12px"
                sizeIcon="16px"
                onClick={onManagePackage}
              />
            </Flex>
          )}
        </Flex>
      </CardHeader>
      {isOpenManagePackage && (
        <ManagePackageGenesisModal
          isOpen={isOpenManagePackage}
          onClose={onCloseManagePackage}
          onManageSuccess={handleManageSuccess}
        />
      )}
      {isOpenPackageOrderHistory && (
        <PackageOrderHistoryModal
          isOpen={isOpenPackageOrderHistory}
          onClose={onClosePackageOrderHistory}
        />
      )}
    </>
  );
};

export default NFTsCardHeader;
