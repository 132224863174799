const END_POINT = {
  LOGIN: "auth/login",
  REGISTER: "auth/register",
  LOGOUT: "auth/logout",
  CHANGE_PASS: "users/change-password",
  RESET_PASSWORD: "auth/reset-password",
  DASHBOARD: "admin/dashboard",

  // users
  USER_PROFILE: "admin/me",
  GET_USER: "users",
  POST_USER: "users",
  GET_USER_BY_ID: "users",
  PUT_USER_BY_ID: "users",
  DELETE_USER_BY_ID: "users",

  // health-check
  GET_HEALTH_CHECK: "health-check",

  // machine
  GET_MACHINE: "machines",
  POST_MACHINE: "machines",
  GET_MACHINE_BY_ID: "machines",
  PUT_MACHINE_BY_ID: "machines",
  DELETE_MACHINE_BY_ID: "machines",

  // machine-group
  GET_MACHINE_GROUP: "machine-group",

  // play fee
  GET_PLAY_FEES: "play-fees",
  POST_PLAY_FEES: "play-fees",
  GET_PLAY_FEES_BY_ID: "play-fees",
  PUT_PLAY_FEES_BY_ID: "play-fees",
  DELETE_PLAY_FEES_BY_ID: "play-fees",

  // role
  ROLE: "roles",
  RULE: "rules",
  RULE_GROUP: "rules/rule-with-rule-group",

  // Revenue sharing
  GET_REVENUE_SHARINGS: "revenue-sharings",
  POST_REVENUE_SHARING: "revenue-sharings",
  GET_REVENUE_SHARING_BY_ID: "revenue-sharings",
  PUT_REVENUE_SHARING_BY_ID: "revenue-sharings",
  DELETE_REVENUE_SHARING_BY_ID: "revenue-sharings",

  // project-categories
  GET_PROJECT_CATEGORY: "project-categories",
  POST_PROJECT_CATEGORY: "project-categories",
  GET_PROJECT_CATEGORY_BY_ID: "project-categories",
  PUT_PROJECT_CATEGORY_BY_ID: "project-categories",
  DELETE_PROJECT_CATEGORY_BY_ID: "project-categories",

  // prize
  GET_PRIZE: "prizes",
  POST_PRIZE: "prizes",
  GET_PRIZE_BY_ID: "prizes",
  PUT_PRIZE_BY_ID: "prizes",
  DELETE_PRIZE_BY_ID: "prizes",
  TYPES_PRIZE: "prizes/types",
  POST_UPLOAD_IMAGE_PRIZE: "upload",

  // referral reward
  GET_REFERRAL_REWARD: "referral-rewards",
  POST_REFERRAL_REWARD: "referral-rewards",
  GET_REFERRAL_REWARD_BY_ID: "referral-rewards",
  PUT_REFERRAL_REWARD_BY_ID: "referral-rewards",
  DELETE_REFERRAL_REWARD_BY_ID: "referral-rewards",

  // reward
  GET_REWARD: "rewards",
  POST_REWARD: "rewards",
  GET_REWARD_BY_ID: "rewards",
  PUT_REWARD_BY_ID: "rewards",
  DELETE_REWARD_BY_ID: "rewards",

  GET_lIST_ROLE: "admin/roles",
  ADD_ROLE: "admin/addRole",
  EDIT_ROLE: "admin/editRole",
  DELETE_ROLE: "admin/deleteRole",

  COURSE_LIST: "admin/",
  COURSE: "admin/",
  CREATE_COURSE: "admin/",
  UPDATE_COURSE: "admin/",

  GET_MACHINE_SETTINGS: "machines",
  CHANGE_MACHINE_GROUP: "machines/change-machine-group",
  GAME_MODE: "game-mode",
  SKILL_RANK: "skill-rank",
  SKILL_POINT: "skill-point",
  LEVEL_REQUIRED: "level-required",
  EVENT: "events",
  EVENT_SKILL: "events/skill-rank",
  EVENT_STANDARD: "events/standard",
  STANDARD_LEADER_BOARD: "standard-leaderboards",
  SKILL_RANK_LEADER_BOARD: "skill-rank-leaderboards",
  EVENT_SIMPLE_RESOURCE: "events/user-simple-resource",
  EVENT_SEND_RESOURCE: "events/send-resource-to-user",
  EVENT_RULE: "events/rules",
  EVENT_WHITE_LIST: "events/whitelist",
  EVENT_TICKET: "user-system-tickets/provide-ticket",
  USER_EVENT: "users/event",
  CRANE_MACHINES: "crane-machines",
  SYSTEM_TICKETS: "system-tickets",
  ROOM_HISTORIES: "room-histories",

  GAMEPLAY_TYPE: "gameplay-types",

  // cabinet type
  CABINET_TYPE_TYPE: "cabinet-type",

  // maintenance
  GET_MAINTENANCE: "system-maintenances",
  POST_MAINTENANCE: "system-maintenances",
  GET_MAINTENANCE_BY_ID: "system-maintenances",
  PUT_MAINTENANCE_BY_ID: "system-maintenances",
  DELETE_MAINTENANCE_BY_ID: "system-maintenances",

  // nfts hunter
  PRODUCT_HUNTER: "product/hunters",
  PRODUCT: "product",
  PRODUCT_SELL_MULTIPLE: "product/sell-multiple",
  PRODUCT_SELL_SINGLE: "product/sell-single",
  PRODUCT_CANCEL_SELL: "product/cancel-sell",
  PRODUCT_TRANSFER: "product/transfer",

  NFT_CONTRACT: "nft-contract",

  NFT_HUNTER: "nft-hunter",
  NFT_HUNTER_SUMMARY: "nft-hunter/summary",
  NFT_HUNTER_CSV: "nft-hunter/export-csv",
  NFT_HUNTER_RE_ALLOCATE: "nft-hunter/allocation",

  NFT_GENESIS: "nft-genesis",
  NFT_GENESIS_SUMMARY: "nft-genesis/summary",
  NFT_GENESIS_CSV: "nft-genesis/export-csv",
  NFT_GENESIS_RE_ALLOCATE: "nft-genesis/allocation",
  NFT_GENESIS_PACKAGE: "nft-genesis-package",
  NFT_GENESIS_PACKAGE_ORDER_HISTORY: "nft-genesis-package/order-history",
  NFT_GENESIS_PACKAGE_ORDER_HISTORY_EXPORT_CSV: "nft-genesis-package/order-history/export-csv",
  NFT_GENESIS_REMOVE_FROM_PACKAGE: "nft-genesis/remove-from-package",
  NFT_GENESIS_ADD_INTO_PACKAGE: "nft-genesis/add-into-package",
  NFT_GENESIS_TRANSFER_TO_PACKAGE: "nft-genesis/update-package",

  HUNTER_OFF_CHAIN: "hunter-off-chain",

  NFT_GAUNTLET: "nft-gauntlet",
  NFT_GAUNTLET_SUMMARY: "nft-gauntlet/summary",
  NFT_GAUNTLET_CSV: "nft-gauntlet/export-csv",
  NFT_GAUNTLET_RE_ALLOCATE: "nft-gauntlet/allocation",

  NFT_BOUNTY_BALL: "nft-bounty-ball",
  NFT_BOUNTY_BALL_SUMMARY: "nft-bounty-ball/summary",
  NFT_BOUNTY_BALL_CSV: "nft-bounty-ball/export-csv",
  NFT_BOUNTY_BALL_RE_ALLOCATE: "nft-bounty-ball/allocation",
  // rate
  EXCHANGE_RATE: "exchange-rate",
  EXCHANGE_RATE_CURRENCY: "exchange-rate/currency",

  // admin
  GET_ADMIN: "admin",
  CREATE_ADMIN: "admin",
  UPDATE_ADMIN_PASSWORD: "admin/change-password",
  UPDATE_ADMIN: "admin/change-admin-role",
  CHANGE_ROLE: "change-admin-role",
  BLOCK_ADMIN: "admin/block-admins",
  UNBLOCK_ADMIN: "admin/unblock-admins",

  // wallet
  WALLET: "master-public-key",

  // contact
  CONTACT: "machine-contact",

  //
  DIGITAL_FIGURE: "digital-figure",

  // Iot device
  IOT_DEVICE: "iot-device/device"
};

export default END_POINT;
