import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { importCSVGenesisNFT } from "api/genesisNFTs.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import FormSelect from "components/form/FormSelect";
import FormUpload from "components/form/FormUpload";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { getContractsApi } from "api/contract.api";
import { TOKEN_TYPE, ALL_NFT_CHAINS, CHAIN_TYPE } from "constants/constants";
import { downloadLocalCSV } from "utils/downloadCSV";
import GenesisNFTCSV from "assets/csv/genesis_nft_new 1.csv";
import HunterNFTCSV from "assets/csv/hunter_nft.csv";
import GauntletNFTCSV from "assets/csv/gauntlet_nft.csv";
import BountyBallNFTCSV from "assets/csv/bounty_ball_nft.csv";
import ModalAddContract from "components/Modal/ModalAddContract";
import ItemCSV from "views/Hunter/import/itemCSV";
import { useSelector } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";
import { importCSVHunterNFT } from "api/hunterNFTs.api";
import { importCSVGauntletNFT } from "api/gauntlet.api";
import { importCSVBountyBallNFT } from "api/bountyBall.api";

export default function NFTsWidgetImport({ tabType }) {
  const toast = useToast();
  const form = useForm();
  const [type, setType] = useState("success");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAddContract,
    onOpen: onOpenAddContract,
    onClose: onCloseAddContract,
  } = useDisclosure();
  const { showToastFail } = useWithToast();
  const [addressContracts, setAddressContracts] = useState([]);
  const { indexTabImport } = useSelector(nftsSelector);
  const [chainOptions, setChainOptions] = useState([]);
  const [contracts, setContracts] = useState([]);

  const {
    getValues,
    setValue,
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "listCSV",
  });

  const getTokenType = (index) => {
    if (index === 0) {
      return TOKEN_TYPE.GENESIS_NFT;
    } else if (index === 1) {
      return TOKEN_TYPE.HUNTER_NFT;
    } else if (index === 2) {
      return TOKEN_TYPE.GAUNTLET_NFT;
    } else if (index === 3) {
      return TOKEN_TYPE.BOUNTY_BALL_NFT;
    }

    return "";
  };

  const getChainOptions = useMemo(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    return [
      ...ALL_NFT_CHAINS.filter((chain) => {
        if (tabType === TOKEN_TYPE.GENESIS_NFT && chain.label.includes("BNB")) {
          return false;
        }
        return isProduction
          ? chain.type === CHAIN_TYPE.MAINNET
          : chain.type === CHAIN_TYPE.TESTNET;
      }).map((chain) => ({
        label: chain.label,
        value: chain.chain_id.toString(),
      })),
    ];
  }, [tabType]);

  const isExitsListCSV = useMemo(() => {
    return fieldCSV && fieldCSV?.length > 0;
  }, [fieldCSV]);

  const isExitCSVFail = useMemo(() => {
    if (fieldCSV && fieldCSV?.length > 0) {
      const list = fieldCSV?.filter((item) => {
        if (item?.isError) {
          return item;
        }
      });
      return list?.length > 0;
    }
    return false;
  }, [fieldCSV]);

  const listNameCSVErrors = useCallback(
    (listError) => {
      if (fieldCSV && fieldCSV?.length > 0) {
        const list = fieldCSV
          ?.filter((item, index) => {
            if (listError[index]) {
              return item;
            }
          })
          .map((item) => item?.file?.name);
        return list?.join(", ");
      }
      return null;
    },
    [fieldCSV]
  );

  const importCSV = async (id, file) => {
    const formData = new FormData();
    formData.append("file", file);

    switch (indexTabImport) {
      case 0:
        return importCSVGenesisNFT(id, formData);
      case 1:
        return importCSVHunterNFT(id, formData);
      case 2:
        return importCSVGauntletNFT(id, formData);
      case 3:
        return importCSVBountyBallNFT(id, formData);
      default:
        throw new Error("Invalid tab index");
    }
  };

  const clearListCSV = () => {
    reset({
      ...getValues(),
      listCSV: [],
    });
  };

  const onSubmit = useCallback(async () => {
    try {
      const idContract = getValues("contract");
      if (isExitsListCSV) {
        const promise = fieldCSV?.map((item) => {
          return importCSV(idContract, item?.file);
        });
        const res = await Promise.all(promise);
        if (res) {
          let arrError = [];
          let isError = false;
          for (let i = 0; i < res?.length; i++) {
            const err = !res[i]?.data?.success;
            if (err) {
              isError = true;
            }
            arrError = arrError.concat(err);
          }
          if (isError) {
            reset({
              ...getValues(),
              listCSV: fieldCSV?.map((item, index) => ({
                ...item,
                isError: arrError[index],
              })),
            });
            setType("error");
            const listNameError = listNameCSVErrors(arrError);
            if (listNameError) {
              if (!res[0]?.data?.data) {
                toast({
                  description: `${res[0]?.data?.messages[0]}`,
                  status: "error",
                  position: "top",
                });
              } else {
                toast({
                  description: `${res[0]?.data?.data[0]?.error_message?.replace(
                    ";",
                    "."
                  )}`,
                  status: "error",
                  position: "top",
                });
              }
            }
            // onOpen();
          } else {
            setType("success");
            onOpen();
            clearListCSV();
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [isExitsListCSV, listNameCSVErrors]);

  const handleOnChangeCSV = (files) => {
    if (files?.length > 0) {
      const listFile = files?.map((file) => {
        return { file };
      });
      if (listFile && listFile?.length > 0) {
        appendCSV(listFile);
      }
    }
  };

  const getContracts = async () => {
    try {
      const tokenType = getTokenType(indexTabImport);
      const { data } = await getContractsApi({
        token_type: tokenType,
      });
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        setContracts(list);
        const chainOptions = [
          ...new Set(list.map((item) => item.chain_name)),
        ].map((chainName) => ({
          label: chainName,
          value: chainName,
        }));
        setChainOptions(chainOptions);
        setValue("chain_name", chainOptions[0].value);
        updateContractOptions(chainOptions[0].value, list);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const updateContractOptions = (selectedChain, contractList = contracts) => {
    const filteredContracts = contractList.filter(
      (contract) => contract.chain_name === selectedChain
    );
    const contractOptions = filteredContracts.map((item) => ({
      label: `${item.chain_name} - ${item.contract_address}`,
      value: item.id,
      chainName: item.chain_name,
    }));
    setAddressContracts(contractOptions);
    setValue("contract", contractOptions[0]?.value || "");
  };

  useEffect(() => {
    getContracts();
  }, [indexTabImport]);

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="role-manager-form-id"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            minWidth: "600px",
            overflowX: "auto",
          }}
        >
          <Flex direction="column" gap={4}>
            <Flex
              alignItems="flex-end"
              justifyContent="flex-end"
              gap={4}
              w="100%"
              p="0px 2px"
            >
              <ButtonType
                padding="16px"
                btnType="primary-new-outline"
                borderRadius="5px"
                onClick={onOpenAddContract}
              >
                Add Contract
              </ButtonType>
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                btnType="primary-new"
                onClick={downloadTemplate}
              >
                Download Template
              </ButtonType>
            </Flex>
            <Flex
              direction="column"
              gap={4}
              w="100%"
              justifyContent="space-betwen"
              p="0px 2px"
            >
              <FormSelect
                name="chain_name"
                label="Chain Name"
                options={chainOptions}
                onChange={(e) => {
                  const selectedChain = e.target.value;
                  setValue("chain_name", selectedChain);
                  updateContractOptions(selectedChain);
                }}
              />
              <FormSelect
                label="Contract address"
                name="contract"
                options={addressContracts}
                w="100%"
                lenSplitLabel={100}
                wrapperProps={{
                  width: "100%",
                }}
                onChange={(e) => {
                  const selectedContract = addressContracts.find(
                    (contract) => contract.value === e.target.value
                  );
                  if (selectedContract) {
                    setValue("chain_name", selectedContract.chainName);
                    setValue("contract", selectedContract.value);
                  }
                }}
              />
            </Flex>
            <Flex direction="column" gap={4} w="100%">
              <FormUpload
                name="csv"
                handleOnChange={handleOnChangeCSV}
                accept=".csv"
              />
            </Flex>
            <Flex
              direction="column"
              gap={2}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {fieldCSV?.map((item, index) => {
                return (
                  <ItemCSV
                    key={item?.id}
                    name={item?.file?.name}
                    isError={item?.isError}
                    indexItem={index}
                    removeCSV={removeCSV}
                  />
                );
              })}
            </Flex>
            <Flex gap="16px" pl="2px" pb="2px">
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                btnType="primary-new-outline"
                disabled={!isExitsListCSV}
                onClick={() => {
                  clearListCSV();
                }}
              >
                Clear
              </ButtonType>
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                type="submit"
                btnType="primary-new"
                disabled={
                  !isExitsListCSV || isExitCSVFail || !watch("contract")
                }
                isLoading={isSubmitting}
              >
                Import
              </ButtonType>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  const downloadTemplate = async () => {
    try {
      let fileName, csv;
      switch (indexTabImport) {
        case 0:
          fileName = "genesis_nft_new 1.csv";
          csv = GenesisNFTCSV;
          break;
        case 1:
          fileName = "hunter_nft.csv";
          csv = HunterNFTCSV;
          break;
        case 2:
          fileName = "gauntlet_nft.csv";
          csv = GauntletNFTCSV;
          break;
        case 3:
          fileName = "bounty_ball_nft.csv";
          csv = BountyBallNFTCSV;
          break;
        default:
          throw new Error("Invalid tab index");
      }
      await downloadLocalCSV(csv, fileName);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column">
      <Card px="24px">
        <CardBody
          display="flex"
          flexDirection="column"
          gap="24px"
          overflowX="auto"
        >
          {renderForm()}
        </CardBody>
      </Card>
      {isOpen && (
        <ModalSuccessAndFail
          isOpen={isOpen}
          type={type}
          onClose={onClose}
          onSubmit={onClose}
          description={
            type === "success"
              ? "You have successfully uploaded the NFT!"
              : "An error occurred. Please try again later!"
          }
        />
      )}
      {isOpenAddContract && (
        <ModalAddContract
          isOpen={isOpenAddContract}
          onClose={onCloseAddContract}
          onAddContractSuccess={() => {
            getContracts();
          }}
          tokenType={tabType}
          chainList={getChainOptions}
        />
      )}
    </Flex>
  );
}
