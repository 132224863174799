import {
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { MACHINE_CONTACT_CATEGORIES, PRIORITY, STATUS } from "views/Contact/constants";
import { convertDateLocalLLLL } from "utils/time";

const ContactRow = ({
  contact,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.id}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.machine_id}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {MACHINE_CONTACT_CATEGORIES[contact?.machine_contact_categories_id]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.message}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.note}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {PRIORITY[contact?.priority]}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertDateLocalLLLL(contact?.create_time * 1000)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {STATUS[contact?.status]}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          {/* <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          /> */}
          <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
          {/* <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          /> */}
        </Flex>
      </Td>
    </Tr>
  );
};

export default ContactRow;
