import { Icon, ViewIcon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Image,
  Badge,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { capitalize } from "lodash";
import {
  TRAIT_NFT,
  TRAIT_NFT_OPTIONS,
  TOKEN_TYPE_LABEL,
} from "constants/constants";
import {
  getAllocationLabel,
  getChainIcon,
} from "views/NFTsManagement/utils/utils";
export default function NFTsManagementRow({
  item,
  nftMngType,
  checkedIds,
  setCheckedIds,
  handleOpenDetailUserModal,
  limit,
  currentPage,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const getTrait = () => {
    if (nftMngType === NFT_MANAGEMENT_TYPE.HUNTER) {
      const traitConfig = {
        arena_hunter_trait: {
          text: TRAIT_NFT.ARENA_HUNTER_TRAIT,
          color: "#FFF599",
        },
        mechanic_trait: { text: TRAIT_NFT.MECHANIC_TRAIT, color: "#C4C1BF" },
        merchant_trait: { text: TRAIT_NFT.MERCHANT_TRAIT, color: "#FF9999" },
        prize_hunter_trait: {
          text: TRAIT_NFT.PRIZE_HUNTER_TRAIT,
          color: "#99E0FF",
        },
        treasure_hunter_trait: {
          text: TRAIT_NFT.TREASURE_HUNTER_TRAIT,
          color: "#99E177",
        },
      };

      const activeTraits = Object.entries(traitConfig)
        .filter(([key]) => item[key])
        .map(([_, config]) => config);

      return (
        <Flex gap={2} flexDirection="column" width="fit-content">
          {activeTraits.map(({ text, color }) => (
            <Badge
              key={text}
              borderRadius="10px"
              padding="4px 8px"
              fontWeight="normal"
              bgColor={color}
              textAlign="center"
            >
              {text}
            </Badge>
          ))}
        </Flex>
      );
    }

    return (
      <Text color={textColor} fontSize="sm" fontWeight={400}>
        {TRAIT_NFT_OPTIONS.find((trait) => trait.value === item?.trait)
          ?.label ?? "-"}
      </Text>
    );
  };

  const getPackageName = (item) => {
    return item?.package_model?.name || "None";
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {(currentPage - 1) * limit + item?.index}
        </Text>
      </Td>
      <Td>
        <Image w="50px" lazyLoad={true} src={item?.image_url} alt="NFT" />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          <Image
            boxSize="30px"
            src={getChainIcon(item?.nft_information?.chain_id).src}
            alt={getChainIcon(item?.nft_information?.chain_id).alt}
          />
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {capitalize(item?.rarity)}
        </Text>
      </Td>
      <Td>
        {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS ? (
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {getPackageName(item) || "None"}
          </Text>
        ) : (
          getTrait()
        )}
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {getAllocationLabel(item?.allocation)}
        </Text>
      </Td>
      {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS && (
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.product_model?.price
              ? `${item?.product_model?.price} ${item?.product_model?.currency}`
              : "None"}
          </Text>
        </Td>
      )}
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TOKEN_TYPE_LABEL[item?.nft_information?.token_type]}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleOpenDetailUserModal(item)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
