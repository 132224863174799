import { useState, useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";
import { setSummaryData } from "stores/nfts/nftsReducer";
import {
  DEFAULT_FILTER_GENESIS_PARAMS,
  DEFAULT_FILTER_PARAMS,
  GENESIS_NFT_TABS_VALUES,
  NFT_MANAGEMENT_TYPE,
} from "views/NFTsManagement/constants";
import { CHAIN_ID_AVAILABLE } from "constants/constants";
import {
  getDetailHunterNFT,
  getHunterNFTs,
  getHunterSummary,
} from "api/hunterNFTs.api";
import {
  getDetailGauntletNFT,
  getGauntletNFTs,
  getGauntletSummary,
} from "api/gauntlet.api";
import {
  getBountyBallNFTs,
  getDetailBountyBallNFT,
  getBountyBallSummary,
} from "api/bountyBall.api";
import {
  getDetailGenesisNFT,
  getGenesisNFTs,
  getGenesisSummary,
} from "api/genesisNFTs.api";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";
import END_POINT from "api/constants";

export const useNFTList = (nftMngType) => {
  const dispatch = useDispatch();
  const { indexTabFilter } = useSelector(nftsSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNFTs] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [params, setParams] = useState(
    nftMngType === NFT_MANAGEMENT_TYPE.GENESIS
      ? DEFAULT_FILTER_GENESIS_PARAMS
      : DEFAULT_FILTER_PARAMS
  );
  const [totalPage, setTotalPage] = useState(0);
  const [nftDetail, setNftDetail] = useState(null);
  const toast = useToast();

  const setNFTParams = (params) => {
    setParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const getChainId = useCallback(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    const chainIdMap = {
      [GENESIS_NFT_TABS_VALUES.IMMUTABLE]: isProduction
        ? CHAIN_ID_AVAILABLE.IMX_MAINNET
        : CHAIN_ID_AVAILABLE.IMX_TESTNET,
      [GENESIS_NFT_TABS_VALUES.DM2C]: isProduction
        ? CHAIN_ID_AVAILABLE.DM2_MAINNET
        : CHAIN_ID_AVAILABLE.DM2_TESTNET,
      [GENESIS_NFT_TABS_VALUES.POLYGON]: isProduction
        ? CHAIN_ID_AVAILABLE.POLYGON_MAINNET
        : CHAIN_ID_AVAILABLE.POLYGON_TESTNET,
      [GENESIS_NFT_TABS_VALUES.BNB_CHAIN]: isProduction
        ? CHAIN_ID_AVAILABLE.BNB_MAINNET
        : CHAIN_ID_AVAILABLE.BNB_TESTNET,
    };
    return chainIdMap[indexTabFilter] || "";
  }, [indexTabFilter]);

  const getNFTs = useCallback(async () => {
    try {
      setIsLoading(true);
      setCheckedIds([]);

      const getNFTsAndSummaryFunctions = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: {
          getNFTs: getHunterNFTs,
          getSummary: getHunterSummary,
        },
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: {
          getNFTs: getGauntletNFTs,
          getSummary: getGauntletSummary,
        },
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: {
          getNFTs: getBountyBallNFTs,
          getSummary: getBountyBallSummary,
        },
        [NFT_MANAGEMENT_TYPE.GENESIS]: {
          getNFTs: getGenesisNFTs,
          getSummary: getGenesisSummary,
        },
      };

      const { getNFTs: getNFTsFunction, getSummary: getSummaryFunction } =
        getNFTsAndSummaryFunctions[nftMngType] || {};

      if (!getNFTsFunction || !getSummaryFunction) {
        throw new Error("Invalid NFT management type");
      }

      const requestParams = {
        ...params,
        ...(nftMngType === NFT_MANAGEMENT_TYPE.GENESIS && {
          chain: getChainId(),
        }),
      };

      const summaryParams = { ...requestParams };
      delete summaryParams.page;
      delete summaryParams.limit;
      if (nftMngType === NFT_MANAGEMENT_TYPE.GENESIS) {
        delete summaryParams.chain;
      }

      const [nftsData, summaryData] = await Promise.all([
        getNFTsFunction(requestParams),
        getSummaryFunction(summaryParams),
      ]);

      if (nftsData?.data?.success) {
        const { records, total_page } = nftsData.data.data;
        setNFTs(records.map((nft, index) => ({ ...nft, index: index + 1 })));
        setTotalPage(total_page);
      } else {
        throw new Error("Failed to fetch NFTs");
      }

      if (summaryData?.data?.success) {
        dispatch(setSummaryData(summaryData.data.data));
      } else {
        throw new Error("Failed to fetch summary data");
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFTs and summary",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params, toast, nftMngType, indexTabFilter, getChainId]);

  const getNFTDetail = async (item) => {
    try {
      setIsLoading(true);
      const getNFTDetailFunction = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: getDetailHunterNFT,
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: getDetailGauntletNFT,
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: getDetailBountyBallNFT,
        [NFT_MANAGEMENT_TYPE.GENESIS]: getDetailGenesisNFT,
      }[nftMngType];

      const { data } = await getNFTDetailFunction(item.id);
      if (data?.success) {
        setNftDetail(data.data);
      }
    } catch (error) {
      toast({
        description:
          error.message || "An error occurred while fetching NFT detail",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNFTs();
  }, [getNFTs, params]);

  const handleDownloadCSV = useCallback(async () => {
    try {
      setIsLoading(true);
      const getNFTsCSVPath = {
        [NFT_MANAGEMENT_TYPE.HUNTER]: END_POINT.NFT_HUNTER_CSV,
        [NFT_MANAGEMENT_TYPE.GENESIS]: END_POINT.NFT_GENESIS_CSV,
        [NFT_MANAGEMENT_TYPE.GAUNTLET]: END_POINT.NFT_GAUNTLET_CSV,
        [NFT_MANAGEMENT_TYPE.BOUNTY_BALL]: END_POINT.NFT_BOUNTY_BALL_CSV,
      }[nftMngType];

      const csvParams = { ...params };
      delete csvParams.page;
      delete csvParams.limit;

      const param = convertParams(omitParams(csvParams));
      const baseUrl = `${getNFTsCSVPath}?${param}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const handleChangePage = useCallback(
    (page) => {
      setNFTParams({ ...params, page });
    },
    [params]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      setNFTParams({ ...params, limit, page: 1 });
    },
    [params]
  );

  const handleChangeChecked = useCallback(
    (isChecked) => {
      if (isChecked) {
        setCheckedIds(nfts);
      } else {
        setCheckedIds([]);
      }
    },
    [nfts]
  );

  return {
    isLoading,
    nfts,
    checkedIds,
    totalPage,
    params,
    nftDetail,
    getNFTs,
    getNFTDetail,
    handleChangePage,
    handleChangeLimit,
    handleChangeChecked,
    handleDownloadCSV,
    setCheckedIds,
    setNFTParams,
  };
};
