import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGenesisNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GENESIS}?${params}`);
};

export const getGenesisSummary = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GENESIS_SUMMARY}?${params}`);
};

export const getDetailGenesisNFT = (id) => {
  return http.get(`${END_POINT.NFT_GENESIS}/${id}`);
};

export const reAllocateGenesisNFT = (data) => {
  return http.put(`${END_POINT.NFT_GENESIS_RE_ALLOCATE}`, data);
};

export const importCSVGenesisNFT = (id, data) => {
  return http.post(`${END_POINT.NFT_GENESIS}/${id}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

export const getGenesisPackageList = (params) => {
  const param = convertParams(omitParams(params));
  return http.get(`${END_POINT.NFT_GENESIS_PACKAGE}?${param}`);
};

export const getGenesisPackageDetail = (id) => {
  return http.get(`${END_POINT.NFT_GENESIS_PACKAGE}/${id}`);
};

export const delistGenesisPackages = (id) => {
  return http.put(`${END_POINT.NFT_GENESIS_PACKAGE}/${id}/delist`);
};

export const listingGenesisPackages = (id) => {
  return http.put(`${END_POINT.NFT_GENESIS_PACKAGE}/${id}/listing`);
};

export const deleteGenesisPackage = (id) => {
  return http.delete(`${END_POINT.NFT_GENESIS_PACKAGE}/${id}`);
};

export const addPackageGenesis = (data) => {
  return http.post(END_POINT.NFT_GENESIS_PACKAGE, data);
};

export const updatePackageGenesis = (id, data) => {
  return http.put(`${END_POINT.NFT_GENESIS_PACKAGE}/${id}`, data);
};

export const transferGenesisToPackage = (data) => {
  return http.put(END_POINT.NFT_GENESIS_TRANSFER_TO_PACKAGE, data);
};

export const removeGenesisFromPackage = (data) => {
  return http.post(END_POINT.NFT_GENESIS_REMOVE_FROM_PACKAGE, data);
};

export const sellMultipleGenesisNFTs = (data) => {
  return http.post(END_POINT.NFT_GENESIS_ADD_INTO_PACKAGE, data);
};

export const getPackageOrderHistory = (params) => {
  const param = convertParams(omitParams(params));
  return http.get(`${END_POINT.NFT_GENESIS_PACKAGE_ORDER_HISTORY}?${param}`);
};
