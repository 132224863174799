import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendResourceUser } from "api/event.api";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { SmallCloseIcon } from "@chakra-ui/icons";
import schemaSendItems from "./schema";

export default function ConfirmSend({
  isOpen,
  onClose,
  checkedIds,
  setCheckedIds
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaSendItems()),
    defaultValues: {
      name: "",
      win_point: null,
      turn_duration: null,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    const listUsers = checkedIds?.map((check) => check?.id)
    const dataSubmit = {
      user_ids: listUsers,
      ticket: data?.tickets,
      bcoin: data?.bcoins,
      sphere: data?.spheres
    };
    try {
      const res = await sendResourceUser(dataSubmit);
      if (res?.data?.success) {
        setCheckedIds([])
        onClose()
        toast({
          title: "Send Resource Successfully!",
          description: "Send Resource Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const filterChecked = (itemRemove) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== itemRemove?.id)
    if (dataFilter?.lenght === 0) {
      onClose()
    }
    setCheckedIds(dataFilter)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <Text fontWeight="500">Indicate the number of items</Text>
            <FormInput
              type="number"
              min={1}
              label="Number of Tickets"
              name="tickets"
              isRequired
              placeholder="Enter number ticket"
            />
            <FormInput
              type="number"
              min={1}
              label="Number of B-Coins"
              name="bcoins"
              isRequired
              placeholder="Enter number ticket"
            />
            <FormInput
              type="number"
              min={1}
              label="Number of Spheres"
              name="spheres"
              isRequired
              placeholder="Enter number ticket"
            />
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          margin="auto"
          color={textColor}
        >
          Receiver(s)
          <Flex alignItems="cetner" flexWrap="wrap" gap="8px" mt="8px">
            {checkedIds?.map((receiver, index) => (
              <Flex
                key={index}
                background="#ccc"
                padding="4px 6px"
                alignItems="center"
                borderRadius="6px"
              >
                <Text fontSize="14px" fontWeight="400">{receiver?.username}</Text>
                <SmallCloseIcon onClick={() => filterChecked(receiver)}/>
              </Flex>
            ))}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="24px"
          minW="500px"
        >
          {renderForm()}
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
