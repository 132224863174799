import React from "react";
import { Flex, VStack, Text, useColorModeValue } from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import GauntletDetail from "../detail/GauntletDetail";
import BountyBallDetail from "../detail/BountyBallDetail";
import HunterDetail from "../detail/HunterDetail";
import GenesisDetail from "../detail/GenesisDetail";

export default function NFTDetailModal({ nftMngType, item, isOpen, onClose }) {
  const textColor = useColorModeValue("gray.700", "white");

  const renderNFTDetails = () => {
    switch (nftMngType) {
      case NFT_MANAGEMENT_TYPE.GAUNTLET:
        return <GauntletDetail item={item} />;
      case NFT_MANAGEMENT_TYPE.BOUNTY_BALL:
        return <BountyBallDetail item={item} />;
      case NFT_MANAGEMENT_TYPE.HUNTER:
        return <HunterDetail item={item} />;
      case NFT_MANAGEMENT_TYPE.GENESIS:
        return <GenesisDetail item={item} />;
      default:
        return <Text color={textColor}>Unknown NFT type</Text>;
    }
  };

  return (
    <ModalBase
      maxWContent={{
        base: "90%",
        sm: "80%",
        md: "70%",
        lg: "60%",
      }}
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={`${nftMngType.toUpperCase()} DETAILS`}
    >
      <VStack spacing={6} align="stretch">
        {renderNFTDetails()}
        <Flex justifyContent="center">
          <ButtonType
            w="28%"
            mt={4}
            type="button"
            text="OK"
            colorScheme="blue"
            onClick={onClose}
          />
        </Flex>
      </VStack>
    </ModalBase>
  );
}
