import {
  Editable,
  EditableInput,
  EditablePreview,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

export default function LeaderBoardRow({
  item,
  index,
  handleChangeRank,
}) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index + 1}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.title_skill_rank}
        </Text>
      </Td>
      <Td>
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.rank_percentage}
          submitOnBlur={true}
          onSubmit={(e) => handleChangeRank(item.id, 'rank_percentage', parseFloat(e))}
        >
          <EditablePreview />
          <EditableInput width="auto"/>
          <span>%</span>
        </Editable>
      </Td>
    </Tr>
  );
}
