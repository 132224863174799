import { Box, Center, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react";
import MachineMapItem from "components/Map/ItemMachine";
import { useEffect, useState } from "react";
import ModalMachineDetail from "./modalMachineDetail";
import ModalConfirm from "components/Modal/ModalConfirm";
import { STATUS } from "constants/constants";
import { MdOutlineDoorbell } from "react-icons/md";
import { restartStatusMachine } from "api/iotDevice.api";

export default function MainMap({ 
  machines, 
  isLoading, 
  handleSwitchStatus, 
  getListMachine, 
  handleSwitchDirection, 
  onDrop, 
  groupLabel, 
  listStatusIot, 
  getListStatusIot
}) {
  const [isLoadingRestart, setLoadingRestart] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [idDetail, setIdDetail] = useState(null);
  const [modal, setModal] = useState({
    confirmPrize: false,
    confirmNext: false,
    confirmRestart: false,
  })

  const handleRestartMachine = async (id, status) => {
    setLoadingRestart(true)
    try {
      const params = {
        device_id: id,
      }
      const { data } = await restartStatusMachine(params);
      if (data?.success) {
        getListStatusIot();
        toast({
          title: "Update iot status successfully!",
          description: "Update iot status successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getListMachine()
      } else {
        toast({
          description: data?.message || data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
      toggle("confirmRestart", null)
      setLoadingRestart(false)
    } catch (error) {
      setLoadingRestart(false)
      toggle("confirmRestart", null)
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const toast = useToast();
  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setIdDetail(data)
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (machines?.length > 0 && idDetail) {
      const detail = machines?.find((item) => item?.id === idDetail);
      if (detail) {
        setDataDetail(detail);
      }
    }
  }, [machines, idDetail]);

  const handleOpenDetail = (item) => {
    setDataDetail(item);
    setIdDetail(item?.id);
    onOpen();
  };

  const handleCloseDetail = () => {
    setDataDetail(null);
    setIdDetail(null);
    onClose();
  };

  const handleNextPlay = () => {
    if (idDetail?.waiting_players > 0) {
      handleSwitchStatus(idDetail?.machine, STATUS.PLAYING)
    } else {
      handleSwitchStatus(idDetail?.machine, STATUS.FREE)
    }
    toggle("confirmNext", null)
  }

  const handleConfirmPrize = () => {
    if (idDetail?.waiting_players > 0) {
      handleSwitchStatus(idDetail?.machine, STATUS.PLAYING)
    } else {
      handleSwitchStatus(idDetail?.machine, STATUS.FREE)
    }
    toggle("confirmPrize", null)
  }

  useEffect(() => {
    machines?.forEach((machine) => {
      if (machine?.machine?.state === STATUS.PRIZE_ACQUIRED) {
        toast({
          position: "top",
          render: () => (
            <Flex p={3} bg="black" align="center" gap="8px" borderRadius="10px">
              <Box as={MdOutlineDoorbell} size="32px" color="white" />
              <Text color="#fff">
                Machine {machine?.mac?.slice(-4)} - {machine?.machine?.group_label}{machine?.machine?.group_number} : Prize setup required!
              </Text>
            </Flex>
          ),
        });
      }
    })
  }, [machines])

  return (
    <>
      <Flex w="100%" gap={4} flexWrap="wrap">
        {machines?.length > 0
          ? machines?.map((item, index) => {
              return (
                <div
                  key={index}
                  onDrop={(event) => onDrop(event, index)}
                  onDragOver={(event) => event.preventDefault()}
                >
                  <MachineMapItem
                    key={item?.id}
                    item={item}
                    index={index}
                    machine={item?.machine}
                    handleOpenDetail={() => {
                      handleOpenDetail(item);
                    }}
                    toggle={toggle}
                    id={item?.id || groupLabel+(index+1)}
                    listStatusIot={listStatusIot}
                    isLoadingRestart={isLoadingRestart}
                  />
                </div>
              );
            })
          : !isLoading && (
              <Center w="100%" minH="100px">
                No Data
              </Center>
            )}
      </Flex>

      {isOpen && (
        <ModalMachineDetail
          dataDetail={dataDetail}
          isOpen={isOpen}
          onClose={handleCloseDetail}
          getListMachine={getListMachine}
          handleSwitchStatus={handleSwitchStatus}
          handleSwitchDirection={handleSwitchDirection}
        />
      )}
      {modal?.confirmNext && (
        <ModalConfirm 
          title="CONFIRM"
          description="Is the machine ready for the next play?"
          textOk="Yes"
          isOpen={modal?.confirmNext} 
          onClose={() => toggle("confirmNext", null)} 
          onSubmit={handleNextPlay} 
        />
      )}
      {modal?.confirmPrize && (
        <ModalConfirm 
          title="CONFIRM"
          description="Please confirm the prize acquisition"
          textOk="Approve"
          textCancel = "Deny"
          isOpen={modal?.confirmPrize} 
          onClose={() => toggle("confirmPrize", null)} 
          onSubmit={handleConfirmPrize} 
        />
      )}
      {modal?.confirmRestart && (
        <ModalConfirm 
          title="RECONNECT"
          description="Try reconnecting the machine"
          textOk="Reconnect"
          textCancel="Cancel"
          isOpen={modal?.confirmRestart} 
          onClose={() => toggle("confirmRestart", null)} 
          onSubmit={() => handleRestartMachine(idDetail?.device_id)} 
        />
      )}
    </>
  );
}
